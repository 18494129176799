import React from 'react'


import "./skylineBg.css"


const SkylineBG = props => {
  const { 
    fillColor,
    viewbox
   } = props

  return(
  <svg id="chiSkyline" 
        data-name="Layer 1" 
          xmlns="http://www.w3.org/2000/svg" 
          viewBox={viewbox}>
        <title>Chicago Skyline</title>
        <g id="airplane" fill={fillColor} opacity="0.87">
          <path d="M1379.5,161.8c3.9.9,12.4,3.7,12.4,3.7l3.6-1.3,6.7-2.4h1.2l-4.2,3.9h0l-.5.5-1.1,1,8,2.4,4.5-1.6.9.5-.9,1.6,3.8-1,1.1.2-4.4,2.3.8.5-.2.4-1.6-.2-1.2,1.9-1.2-.2-.2-1.8-3.4.1-5.2-1.5-2.2-.4-1.2-.2-2.3,1-2.6,3.5-1.2-.6.7-5.8s-9-2.2-11.3-2.9a6.57,6.57,0,0,1-3.3-2.4C1374.8,162.7,1375.6,161,1379.5,161.8Z"/>
        </g>
          <g class="filler" fill={fillColor}>
            <polygon points="1038.4 398.7 1047.9 398.7 1047.9 260.6 1038.4 260.6 1038.4 398.7"/>
            <polygon points="1158.7 277 1144 277 1144 398.7 1167.3 398.7 1167.3 376.9 1183 376.9 1183 255.2 1158.7 255.2 1158.7 277"/>
            <polygon points="1211 375 1229.6 375 1229.6 217.4 1211 217.4 1211 375"/>
            <polygon points="1254.5 407.5 1274 407.5 1274 264.5 1254.5 264.5 1254.5 407.5"/>
            <polygon points="438.8 386.6 466.3 386.6 466.3 266.1 438.8 266.1 438.8 386.6"/>
            <polygon points="195 393.1 244 393.1 244 272.7 195 272.7 195 393.1"/>
            <polygon points="1383.9 343.3 1383.9 299.6 1376.4 299.6 1376.4 398.7 1352.8 398.7 1352.8 295.4 1355.5 295.4 1355.5 292.8 1360.4 292.8 1360.4 295.4 1362.6 295.4 1362.6 292.8 1366.8 292.8 1366.8 295.4 1369.1 295.4 1369.1 292.8 1369.2 292.8 1369.2 264.5 1348.2 264.5 1348.5 407 1360 407.5 1360 411.3 1398.7 411.3 1398.7 343.3 1383.9 343.3"/>
            <polygon points="778.9 381.5 790.4 381.5 790.4 224.4 778.9 224.4 778.9 381.5"/>
            <polygon points="736.8 398 750.4 398 750.4 240.9 736.8 240.9 736.8 398"/>
          </g>
        <g id="background" data-name="background" class="backBuildings" fill={fillColor}>
            <polygon points="1057.4 233.3 1057.4 240.9 1050.1 240.9 1050.1 246.4 1047.4 246.4 1047.4 396.8 1068.4 396.8 1068.4 256.9 1074.5 256.9 1074.5 247.9 1074.5 246.4 1074.5 242.2 1074.5 240.9 1074.5 233.3 1057.4 233.3"/>
            <polygon points="430.5 244.5 408.5 244.5 408.5 227.3 388.9 227.3 388.9 244.5 366.9 244.5 366.9 248.4 364.6 248.4 364.6 211.7 323.4 211.7 323.4 227 323.4 229 322.4 229 322.4 232.8 323.4 232.8 324.2 232.8 324.2 238.8 326.3 238.8 326.3 254.8 330.1 254.8 330.1 398.7 336.7 398.7 336.7 237.7 338.4 237.7 338.4 235.5 343.7 235.5 343.7 231.5 351.6 231.5 351.6 237.7 353.1 237.7 353.1 297.3 356.6 297.3 356.6 297.4 405.4 297.4 405.4 291.6 412.5 291.6 412.5 286.4 415.9 286.4 415.9 283.8 420 283.8 420 280.4 422.6 280.4 422.6 283.8 423.2 283.8 423.2 258.7 425.3 258.7 425.3 283.8 430.6 283.8 430.6 286.4 432.7 286.4 432.7 291.6 434.5 291.6 434.5 297.4 438.8 297.4 438.8 266.1 440.7 266.1 440.7 248.4 430.5 248.4 430.5 244.5"/>
            <polygon points="284.6 283.4 282.5 283.4 282.5 281.6 279 281.6 279 283.4 276.8 283.4 276.8 281.6 273.3 281.6 273.3 283.4 271.2 283.4 271.2 281.6 267.7 281.6 267.7 283.4 267.5 283.4 267.5 389.3 287.6 389.3 287.6 281.6 284.6 281.6 284.6 283.4"/>
            <polygon points="870 226.6 844 226.6 844 236.5 858.1 236.5 858.1 247.7 858.1 255 858.1 398.7 830.3 398.7 830.3 406.2 891.9 406.2 891.9 406.1 873.4 406.1 873.4 319.7 891.9 319.7 891.9 255.6 882.9 255.6 882.9 250.4 891.9 250.4 891.9 247.7 870 247.7 870 226.6"/>
            <polygon points="631.9 237.1 621.7 237.1 621.7 250.4 604.4 250.4 604.4 407.5 641 407.5 641 404.6 660.3 404.6 660.3 398.7 631.9 398.7 631.9 237.1"/>
            <path d="M174.5,246.6h2.6v-4.5H128.6v-2.4H121v2.4h-6.6v4.5H117v13.5H103.6v40.6h-.5v1.4H70.9V352H19.3v50.5H70.9v1h40.7v-1.9h29.6v-.8H110.4V354.5h6.3v-5.8h14.6v-5.1h11.4v-2.4h1.8v-1.3h1.8v-1.7h.2v-4.4h-.2v-2.4h.2a5.47,5.47,0,0,1,5-5.5v-15h.8v15.2a5.47,5.47,0,0,1,5,5.5h.2V334h-.2v4.4h.2v1.7h1.8v1.3h1.8v2.4h11.4v5.1h5.8V281h3.9V260.1h-7.8V246.6Z"/>
            <polygon points="531.5 398.7 531.5 219.7 542.2 219.7 542.2 213.8 510.5 213.8 510.5 233.5 505 233.5 505 225.9 469.5 225.9 469.5 412.9 481.5 412.9 481.5 420.5 516.9 420.5 516.9 400.9 546 400.9 546 398.7 542.2 398.7 531.5 398.7"/>
        </g>
        <g id="midground" data-name=" 3-midground" class="midBuildings" fill={fillColor}>
          <polygon points="1373.6 295.4 1373.6 292.8 1369.1 292.8 1369.1 295.4 1366.8 295.4 1366.8 292.8 1362.6 292.8 1362.6 295.4 1360.4 295.4 1360.4 292.8 1355.5 292.8 1355.5 295.4 1352.8 295.4 1352.8 398.7 1376.4 398.7 1376.4 295.4 1373.6 295.4"/>
          <polygon points="773.1 207.2 756.1 207.2 756.1 213.2 750.4 213.2 750.4 398.7 778.9 398.7 778.9 213.2 773.1 213.2 773.1 207.2"/>
          <polygon points="1305 244 1299.8 244 1299.8 235.2 1286.5 235.2 1286.5 244 1281.2 244 1281.2 260.1 1274.6 260.1 1274.6 398.7 1310.5 398.7 1310.5 260.1 1305 260.1 1305 244"/>
          <polygon points="1026.3 156.3 1023.7 156.3 1023.7 152.7 1018 152.7 1018 156.3 1015.8 156.3 1015.8 145 1007.7 145 1007.7 138 1004.9 138 1004.9 145 996.8 145 996.8 156.3 994.7 156.3 994.7 152.7 989 152.7 989 156.3 986.3 156.3 986.3 225.9 974 225.9 974 328.9 942.1 328.9 942.1 362.9 910.4 362.9 910.4 319.7 873.4 319.7 873.4 408.1 898.3 408 922.3 407.4 1038.7 407.7 1038.7 225.9 1026.3 225.9 1026.3 156.3"/>
          <polygon points="351.6 231.5 343.7 231.5 343.7 235.5 338.4 235.5 338.4 237.7 336.7 237.7 336.7 403.6 353.1 403.6 353.1 399.2 343.4 399.2 343.4 312.8 343.4 305.8 343.4 297.4 353.1 297.4 353.1 237.7 351.6 237.7 351.6 231.5"/>
          <path d="M265,105.6h0c-1-2-11.8-1-24.5,2.4-11.8,3.1-9.2,7.2-10.3,9.6h-.1v6.8l-4-.5v12.4h-3.9v35.5h-6v2.1h-2.7V252H182.3v29.2h-3.9v67.9h-5.8V344H161.2v-2.4h-1.8v-1.3h-1.8v-1.7h-.2v-4.4h.2v-2.4h-.2a5.47,5.47,0,0,0-5-5.5V311h-.8v15.3a5.47,5.47,0,0,0-5,5.5h-.2v2.4h.2v4.4h-.2v1.7h-1.8v1.3h-1.8V344H131.3v5.1H116.7v5.8h-6.3v46.3h83.1v-.6h74V130.8l-2.5-.3Z"/>
          <polygon points="475.6 209.4 463.5 209.4 463.5 215.8 455.5 215.8 455.5 266.1 466.3 266.1 466.3 386.6 455.5 386.6 455.5 396.4 469.5 396.4 469.5 225.9 483.5 225.9 483.5 215.8 475.6 215.8 475.6 209.4"/>
        </g>
        <g id="_2-willis" class="willis" fill={fillColor} opacity="0.78">
          <polygon points="697.7 223.8 689.2 223.8 689.2 217.9 689.2 214.4 686.4 214.4 686.4 217.9 681.3 217.9 681.3 214.4 680.3 214.4 680.3 195.8 679 195.8 679 214.4 676 214.4 676 189.9 675 189.9 675 214.4 675 216.2 675 217.9 668.1 217.9 668.1 214.4 665.3 214.4 665.3 217.9 665.3 223.8 660.4 223.8 660.4 231 631.9 231 631.9 398.7 736.9 398.7 736.9 231 697.7 231 697.7 223.8"/>
          <polygon points="1338.2 250.8 1338.2 241.3 1336.1 241.3 1336.1 238.6 1326.5 238.6 1326.5 241.3 1310.5 241.3 1310.5 250.8 1310.5 254.9 1310.5 398.7 1348.4 398.7 1348.4 250.8 1338.2 250.8"/>
          <polygon points="1101 253.9 1092.5 253.9 1092.5 250.9 1084.3 250.9 1084.3 253.9 1075.8 253.9 1075.8 256.9 1068.4 256.9 1068.4 399.2 1108.3 399.2 1108.3 256.9 1101 256.9 1101 253.9"/>
          <polygon points="835.7 234.2 827.6 234.2 827.6 231.5 817 231.5 817 234.2 798.3 234.2 798.3 236.5 790.4 236.5 790.4 398.7 858.1 398.7 858.1 236.5 835.7 236.5 835.7 234.2"/>
          <polygon points="1205.8 189.8 1202.3 189.8 1202.3 195.1 1200.3 195.1 1200.3 189.8 1195.2 189.8 1195.2 195.1 1193.2 195.1 1193.2 189.8 1189.5 189.8 1189.5 195.1 1183.5 195.1 1183.5 398.7 1211.8 398.7 1211.8 195.1 1205.8 195.1 1205.8 189.8"/>
          <polygon points="326.3 238.8 324.2 238.8 324.2 232.8 323.4 232.8 322.4 232.8 322.4 229 323.4 229 323.4 227 322.4 227 322.4 213.3 318.5 213.3 308.8 200.4 299.1 213.3 295.3 213.3 295.3 227 293.9 227 293.9 229 295.3 229 295.3 232.8 293.5 232.8 293.5 238.8 291.3 238.8 291.3 254.8 287.6 254.8 287.6 401.1 330.1 401.1 330.1 398.7 330.1 254.8 326.3 254.8 326.3 238.8"/>
          <polygon points="593.6 141.4 585.1 141.4 585.1 70.5 577.8 70.5 577.8 35.8 575.9 35.8 575.9 67.9 573.8 67.9 573.8 65.5 573.7 65.5 573.7 1.9 569.9 1.9 569.9 34.1 568.6 34.1 568.6 65.5 565.6 65.5 565.6 18 564.5 18 564.5 0 561.6 0 561.6 18 560.6 18 560.6 67.9 559.8 67.9 559.8 51 557.6 51 557.6 70.5 556.3 70.5 556.3 37.7 554.2 37.7 554.2 70.5 550.8 70.5 550.8 141.4 542.2 141.4 542.2 219.7 531.5 219.7 531.5 398.7 542.2 398.7 593.6 398.7 604.3 398.7 604.3 219.7 593.6 219.7 593.6 141.4"/>
          <polygon points="439.3 297.4 434.5 297.4 434.5 291.6 432.7 291.6 432.7 286.4 430.6 286.4 430.6 283.8 425.3 283.8 425.3 258.7 423.2 258.7 423.2 283.8 422.6 283.8 422.6 280.4 420 280.4 420 283.8 415.9 283.8 415.9 286.4 412.5 286.4 412.5 291.6 405.4 291.6 405.4 297.4 353.1 297.4 343.4 297.4 343.4 305.8 343.4 312.8 343.4 399.2 353.1 399.2 453.1 399.2 453.1 381.1 439.3 381.1 439.3 297.4"/>
        </g>
        <g id="foreground" data-name="foreground" fill={fillColor}>
          <path d="M1021,379l-23.6.5v8.3h-7.6v19.6H910.7V384.5H891.3v-12h-.6v-3.4h.5V365H890l-3.8-3.5a1.4,1.4,0,1,0-1.9-1.3h0a1.34,1.34,0,0,0,.8,1.3l-3.7,3.5h-1.2v2.5h-1.3v1.6h1.3v3.4h-.8v12.3h-.8v1.6h1.6v1H870V373.8H859.6V356.2H840.5v-4.8h.3v-2.8h-1v.8h-.4v1h-.7l-27-12.7v-1h-.8V336h-.8v-.6h-1v.6h-.8v.7h-.8v1l-27,12.7v-.1h-.7v-1h-.4v-.8h-1v2.7h.3v4.9H759.6v33H740V330h-1.8v-2.5H689.3v-6.6H677.4v45h-7v-8.4h-5.6V323.9H668v-4.1h-3.2v-2.7H647.4v-3h-4v-5h-6.9v-5h-8.3v5h-6.9v5h-4v3H599.9v2.7h-3.2v4.1h3.2v50.6h-9.1v23.4H579V301.5h-3V283.8h-2.1V272.9l-13-7.8v-1.6h-1.3v-.2l-.3-.2.1-1.9.1-2h.3a8.86,8.86,0,0,0-.1-2.3,1.94,1.94,0,0,0-.7-.2v-.3a.72.72,0,0,0,.2-.5c0-.3-.2-.6-.4-.6s-.4.3-.4.6a.72.72,0,0,0,.2.5v.4a1.94,1.94,0,0,0-.7.2,8.86,8.86,0,0,0-.1,2.3h.3l.1,2,.1,1.9-.3.2v.2h-1.3v1.6l-13,7.8v10.8h-2.1v17.6h-3v58.3H523.7V302.5h-2.5v-6.6H508.8v6.6h-2.4V282.8h1.9v-4h-1.9v-7.1h-2.8v-4.4h-2.2v-2.7h-1.2v-3.5h-2.4v-6.2h-.9V252H494v-2h-1.8v-4.5h-1.9v4.4h-1.8v2h-2.9v2.9h-.9V261h-2.4v3.5H481v2.7h-2.2v4.4H476v7.1h-1.9v4H476v19.8h-2.4v-6.6H461.2v6.6h-2.5v81.3H422.2v14H408.5c5.8-1.1,8.6-5.3,8.9-12.7.5-16.2-18.5-32.1-42.6-32.9s-45.1,16.4-45.6,32.6c-.2,6.7,2.3,11.2,7.5,13H319.9V380.9H304.4v-4.1H268.2v-8.3h-.1l-14.3-14.3V336h-.5v18.7l-13.8,13.8h-.6v8.3H202.7v4.1H187.2v16.9H164.5V382.2H123.4l-.1,6.5-36-.5h0v9.7H70.7V382.8H39.2v14.8H0v68.9H1417.3V397.8h-30.6V386.2h-57.8V307.9h-2.7V306h-13.5v1.9h-3v-4.4h-9.1v-1h-4.1v1.1h-5V308h-11.4v-5.5h-12.2V308h-3.8v68h-5V210.4h-3.3v-2.2H1229v2.2h-3.3V359.5h-6.5V324.1h-3.3v-4h-1v-.9h-1.4v.9h-2.1v-2.9h-2.1v2.9h-2.4v4h-2.6v-6h-30v6h-12.1v56.5h-7.6V240.3h-1.4v-.1l-7.3-19.7-1.7,4.6-9.1-15.2h0V191.5h-2.7V210h0l-9.1,15.2-1.7-4.6-7.3,19.7v.1h-1.4v95.2h-10.4V398H1096V312.9h-.2l-18.8-25v13.9h-1.6V288.5l-14.3,24.2h-.1v57.8h-15.6v-5.3h-.8v-3.4h1.3v-1.6h-1.3v-2.5h-1.3l-3.8-3.5a1.4,1.4,0,1,0-1.9-1.3h0a1.34,1.34,0,0,0,.8,1.3l-3.7,3.5h-1.2v2.5h-1.3v1.6h1.3v3.4h-.8v10.9l-11.7.2V379Zm-646.2,9.3c11.1,5.4,19.9,8.6,26.6,9.5H350C356.6,396.5,364.8,393.4,374.8,388.3Z"/>
        </g>
        <g id="stands" class="stroke-nofill">
          <polygon points="91.2 341.4 68.8 397.5 116.3 397.5 91.2 341.4"/>
          <polygon points="91.2 341.4 61.1 397.5 123.8 397.5 91.2 341.4"/>
          <path d="M100.2,281"/>
        </g>
        <g id="cars-spokes">
          <g id="spokes" class="stroke-nofill">
            <line x1="91.2" y1="296" x2="91.2" y2="341.4"/>
            <line x1="113.9" y1="302.1" x2="91.2" y2="341.4"/>
            <line x1="130.5" y1="318.7" x2="91.2" y2="341.4"/>
            <line x1="136.6" y1="341.3" x2="91.2" y2="341.4"/>
            <line x1="130.5" y1="364" x2="91.2" y2="341.4"/>
            <line x1="114" y1="380.6" x2="91.1" y2="341.4"/>
            <line x1="91.3" y1="386.7" x2="91.2" y2="341.4"/>
            <line x1="68.6" y1="380.7" x2="91.2" y2="341.4"/>
            <line x1="52" y1="364.1" x2="91.2" y2="341.4"/>
            <line x1="45.9" y1="341.4" x2="91.2" y2="341.4"/>
            <line x1="51.9" y1="318.8" x2="91.2" y2="341.4"/>
            <line x1="68.5" y1="302.1" x2="91.2" y2="341.4"/>
          </g>
          <g id="cars" fill={fillColor}>
            <g>
              <path d="M97.2,296.2a6,6,0,0,1-12,0v-.4a6,6,0,0,1,12,0Z"/>
              <path d="M118.9,305.2a6,6,0,0,1-8.1,2.1,5.78,5.78,0,0,1-2.23-7.85l0-.05a.09.09,0,0,1,.1-.1h0l.2-.3a6,6,0,0,1,8.1-2.1,5.78,5.78,0,0,1,2.23,7.85l0,.05a.09.09,0,0,1-.1.1h0Z"/>
              <path d="M133.3,323.9a5.79,5.79,0,0,1-7.9-2,.09.09,0,0,0-.1-.1h0a6,6,0,0,1,2.1-8.1l.3-.2a5.74,5.74,0,0,1,7.86,2l0,.07a.09.09,0,0,0,.1.1h0a6,6,0,0,1-2.1,8.1Z"/>
              <path d="M127.5,369a6,6,0,0,1-2.1-8.1,5.78,5.78,0,0,1,7.85-2.23l.05,0a.09.09,0,0,1,.1.1h0l.3.2a6,6,0,0,1,2.1,8.1,5.78,5.78,0,0,1-7.85,2.23l0,0a.09.09,0,0,1-.1-.1h0Z"/>
              <path d="M108.8,383.4a5.94,5.94,0,0,1,2-8h.1a5.86,5.86,0,0,1,8,2.1l.2.3a5.81,5.81,0,0,1-2,7.9.09.09,0,0,0-.1.1h0a6,6,0,0,1-8.1-2.1Z"/>
              <path d="M85.3,386.5a6,6,0,0,1,12,0v.4a6,6,0,0,1-12,0Z"/>
              <path d="M63.6,377.6a6,6,0,0,1,8.1-2.1,5.78,5.78,0,0,1,2.23,7.85l0,0a.09.09,0,0,1-.1.1h0l-.2.3a6,6,0,0,1-8.1,2.1,5.78,5.78,0,0,1-2.23-7.85l0-.05a.09.09,0,0,1,.1-.1h0Z"/>
              <path d="M49.2,358.9a5.79,5.79,0,0,1,7.9,2,.09.09,0,0,0,.1.1h0a6,6,0,0,1-2.1,8.1l-.3.2a5.79,5.79,0,0,1-7.9-2,.09.09,0,0,0-.1-.1h0a6,6,0,0,1,2.1-8.1Z"/>
              <path d="M46.1,335.4a6,6,0,1,1,0,12h-.4a6,6,0,1,1,0-12Z"/>
              <path d="M55,313.7a6,6,0,0,1,2.1,8.1A5.78,5.78,0,0,1,49.25,324l0,0a.09.09,0,0,1-.1-.1h0l-.3-.2a6,6,0,0,1-2.2-8.1,5.9,5.9,0,0,1,7.9-2.3.09.09,0,0,1,.1.1h0Z"/>
              <path d="M74.3,300.9a5.71,5.71,0,0,1-4.08,7l-.12,0H70a6,6,0,0,1-7.2-4.2l-.1-.4a5.71,5.71,0,0,1,4.08-7l.12,0H67a5.92,5.92,0,0,1,7.2,4.2Z"/>
              <path d="M136.4,347.3a6,6,0,0,1,0-12h.4a6,6,0,0,1,0,12Z"/>
            </g>
            <g>
              <path d="M97.2,296.2a6,6,0,0,1-12,0v-.4a6,6,0,0,1,12,0Z"/>
              <path d="M118.9,305.2a6,6,0,0,1-8.1,2.1,5.78,5.78,0,0,1-2.23-7.85l0-.05a.09.09,0,0,1,.1-.1h0l.2-.3a6,6,0,0,1,8.1-2.1,5.78,5.78,0,0,1,2.23,7.85l0,.05a.09.09,0,0,1-.1.1h0Z"/>
              <path d="M133.3,323.9a5.79,5.79,0,0,1-7.9-2,.09.09,0,0,0-.1-.1h0a6,6,0,0,1,2.1-8.1l.3-.2a5.74,5.74,0,0,1,7.86,2l0,.07a.09.09,0,0,0,.1.1h0a6,6,0,0,1-2.1,8.1Z"/>
              <path d="M127.5,369a6,6,0,0,1-2.1-8.1,5.78,5.78,0,0,1,7.85-2.23l.05,0a.09.09,0,0,1,.1.1h0l.3.2a6,6,0,0,1,2.1,8.1,5.78,5.78,0,0,1-7.85,2.23l0,0a.09.09,0,0,1-.1-.1h0Z"/>
              <path d="M108.8,383.4a5.94,5.94,0,0,1,2-8h.1a5.86,5.86,0,0,1,8,2.1l.2.3a5.81,5.81,0,0,1-2,7.9.09.09,0,0,0-.1.1h0a6,6,0,0,1-8.1-2.1Z"/>
              <path d="M85.3,386.5a6,6,0,0,1,12,0v.4a6,6,0,0,1-12,0Z"/>
              <path d="M63.6,377.6a6,6,0,0,1,8.1-2.1,5.78,5.78,0,0,1,2.23,7.85l0,0a.09.09,0,0,1-.1.1h0l-.2.3a6,6,0,0,1-8.1,2.1,5.78,5.78,0,0,1-2.23-7.85l0-.05a.09.09,0,0,1,.1-.1h0Z"/>
              <path d="M49.2,358.9a5.79,5.79,0,0,1,7.9,2,.09.09,0,0,0,.1.1h0a6,6,0,0,1-2.1,8.1l-.3.2a5.79,5.79,0,0,1-7.9-2,.09.09,0,0,0-.1-.1h0a6,6,0,0,1,2.1-8.1Z"/>
              <path d="M46.1,335.4a6,6,0,1,1,0,12h-.4a6,6,0,1,1,0-12Z"/>
              <path d="M55,313.7a6,6,0,0,1,2.1,8.1A5.78,5.78,0,0,1,49.25,324l0,0a.09.09,0,0,1-.1-.1h0l-.3-.2a6,6,0,0,1-2.2-8.1,5.9,5.9,0,0,1,7.9-2.3.09.09,0,0,1,.1.1h0Z"/>
              <path d="M74.3,300.9a5.71,5.71,0,0,1-4.08,7l-.12,0H70a6,6,0,0,1-7.2-4.2l-.1-.4a5.71,5.71,0,0,1,4.08-7l.12,0H67a5.92,5.92,0,0,1,7.2,4.2Z"/>
              <path d="M136.4,347.3a6,6,0,0,1,0-12h.4a6,6,0,0,1,0,12Z"/>
            </g>
          </g>
        </g>
        <g id="circles">
          <g>
            <circle class="stroke-nofill" cx="91.2" cy="341.4" r="24"/>
            <circle class="stroke-nofill" cx="91.2" cy="341.4" r="32"/>
            <circle class="stroke-nofill" cx="91.2" cy="341.43" r="45.4" transform="translate(-259.7 383.4) rotate(-81.86)"/>
            <circle fill={fillColor} cx="91.2" cy="341.4" r="2.5"/>
          </g>
        </g>
      </svg>
  )
}



export default SkylineBG;
